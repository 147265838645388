import React, { useEffect, useState } from 'react';
import Page from '@molecules/Page';
import { Claim } from '@molecules/Slides';

const ClaimPage = () => {
  const [claimID, setClaimID] = useState(null);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams) {
        console.log('urlParams', urlParams.get('claimID'));
        setClaimID(urlParams.get('claimID'));
      }
    }
  }, []);

  const [stateSlide, setStateSlide] = useState(0);
  useEffect(() => {}, [stateSlide]);
  return (
    <Page stateSlide={stateSlide} setStateSlide={setStateSlide}>
      {
        {
          0: <Claim claimID={claimID} />,
        }[stateSlide]
      }
    </Page>
  );
};

export default ClaimPage;
